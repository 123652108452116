import React, { useCallback, useState } from 'react';
import { withLayout, LayoutProps } from "../components/Layout"
import { Container, Header, Segment, Form, Input, TextArea, FormProps, Button, Message } from 'semantic-ui-react';
import SEO from '../components/SEO';


interface ContactUsState {
    name: string;
    email: string;
    details: string;
    bot?: string;
}

const encodeFormData = (data: any) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

const Email_Regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Page: React.SFC<LayoutProps> = ({location:{pathname}}) => {
    const [state, setState] = useState<Partial<ContactUsState>>({
        name: '',
        email: '',
        details: ''
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formStatus, setFormStatus] = useState<{ success?: boolean, error?: boolean }>({});

    const handleChange = useCallback((e, { name, value }: { name: keyof (ContactUsState), value: string }) => {
        setFormStatus({});
        setState((s) => ({ ...s, [name]: value }));
        setErrors({ [name]: undefined });
    }, []);

    const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>, { name: formName }) => {
        if (!Email_Regex.test(state.email)) {
            setErrors({ email: "Invalid email address" });
            return;
        }

        fetch("/", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: encodeFormData({ ["form-name"]: formName, ...state })
        }).then(() => {
            setFormStatus({ success: true });
            setErrors({});
            setState({ name: '', email: '', details: '' });
        }).catch(error => {
            setFormStatus({ error: true })
        })

        event.preventDefault();
    }, [state]);

    const { name, email, details, bot } = state;

    return (
        <Container style={{ paddingTop: "1em" }}>
            <SEO
                path={pathname}
                title="Contact Us"
                description="Contact Rough Draft Developments"
            />
            <Segment padded="very">

                <Header textAlign="center" as={"h1"} >Let's Work Together</Header>
                <Segment vertical style={{ paddingBottom: "2em", marginBottom: "2em" }}>
                We would love to connect and learn more about what your team is working on. If it seems like a good fit, we can discuss options on how we can work together.<br/>
                Even if you are not looking for help right away, we are always up for talking tech!
                </Segment>

                <Form
                    {...formStatus}
                    onSubmit={handleSubmit}
                    name="contact"
                    data-netlify="true"
                    data-netlify-honeypot="bot"
                >
                    <input type="hidden" name="form-name" value="contact" />
                    <Message
                        success
                        header="Form Submitted!"
                        content="Someone from our team will follow up with you shortly"
                    />
                    <Message
                        error
                        header="Error"
                        content="An error occured while submitting the form."
                    />
                    <div hidden>
                        <Form.Field
                            name="bot"
                            control={Input}
                            label="Don't Fill this out"
                            placeholder="Name"
                            value={bot}
                            onChange={handleChange}
                        />
                    </div>
                    <Form.Field
                        name="name"
                        control={Input}
                        label="Name"
                        placeholder="Name"
                        value={name}
                        onChange={handleChange}
                        required
                    />
                    <Form.Field
                        control={Input}
                        label="Email Address"
                        name="email"
                        value={email}
                        placeholder="Email Address"
                        type="email"
                        onChange={handleChange}
                        required
                        error={errors["email"] && {
                            content: errors["email"]
                        }}
                    />
                    <Form.Field
                        name="details"
                        control={TextArea}
                        label="How can we help?"
                        value={details}
                        placeholder="Tell us about your project"
                        onChange={handleChange}
                        required
                    />
                    <Button secondary>Submit</Button>
                </Form>
            </Segment>
        </Container>
    )
}

export default withLayout(Page);